
import {
    difference
} from "lodash-es";
import {
    ref
} from "vue";
import _ from "lodash"
export default {
    props: {
        visible: Boolean,
        current: Object
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        showModal: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            }
        },
        leftColumns() {
            return [{
                    dataIndex: "code",
                    title: "客户代码"
                },
                {
                    dataIndex: "account",
                    title: "用户账号"
                }
            ]
        }
    },
    methods: {
        getList() {
            this.$http.get("/admin/user/list", {
                params: {
                    page: 1,
                    limit: 999
                }
            }).then(res => {

                this.userData = res.data.data.map(item => {
                    item.title = item.account;
                    item.key = item.id.toString();
                    item.id = item.id.toString();
                    return item
                })

                this.querry(this.current.id);

            })
        },
        /**
         * 控制穿梭框
         * @param next 传输右边数据
         * @direction 动作描述
         * @move 移动的数据
         */
        onChange(next, direction, move) {
            if (direction == 'right') {
                this.onBindAuth(move);
            } else {
                this.onRemoveAuth(move);
            }
        },
        /**
         * 删除权限
         * @arr 数据
         */
        onRemoveAuth(arr) {
            this.$http.post("/admin/role_auth/delete", {
                "user_ids": arr.join(","),
                "menu_id": this.current.id
            }).then(res => {

                if (res.data.code == 0) {
                    this.targetKeys = _.difference(this.targetKeys, arr);
                    this.$message.success("操作成功")
                }

            }).catch(() => {

                this.$message.error("操作失败")

            }).finally(() => {
                this.loading = false;
            })
        },
        /**
         * 绑定权限
         * @arr 数据
         */
        onBindAuth(arr) {
            this.loading = true;

            this.$http.post("/admin/role_auth/update", {
                "user_ids": arr.join(","),
                "menu_id": this.current.id
            }).then(res => {

                if (res.data.code == 0) {
                    this.targetKeys = arr;
                    this.$message.success("操作成功")
                }

            }).catch(() => {

                this.$message.error("操作失败")

            }).finally(() => {
                this.loading = false;
            })
        },
        /**
         * 查询权限数据
         */
        querry(menu_id) {
            this.$http.get("/admin/role_auth/list", {
                params: {
                    menu_id
                }
            }).then(res => {
                this.targetKeys = res.data.data.map(r => r.user_id.toString());
            })
        },
        /**
         * 过滤
         */
        handleFilter(inputValue, item) {
            let pass = false;
            const arr = ['code','account','contact_name'];
            for(let key of arr){
                pass = item[key].indexOf(inputValue) !== -1;
                if(pass){
                    break;
                }
            }

            return pass
        }
    },
    created() {
        this.getList();
    },
    setup() {
        const userData = ref([]);
        const targetKeys = ref([]);
        const disabled = ref(false);
        const showSearch = ref(false);

        const getRowSelection = ({
            disabled,
            selectedKeys,
            onItemSelectAll,
            onItemSelect
        }) => {
            return {
                getCheckboxProps: item => ({
                    disabled: disabled || item.disabled
                }),

                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({
                            key
                        }) => key);
                    const diffKeys = selected ?
                        difference(treeSelectedKeys, selectedKeys) :
                        difference(selectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },

                onSelect({
                    key
                }, selected) {
                    onItemSelect(key, selected);
                },

                selectedRowKeys: selectedKeys
            };
        };

        return {
            userData,
            targetKeys,
            disabled,
            showSearch,
            getRowSelection
        };
    }
};
